import Chart from 'react-apexcharts';

type ColumnChartType = {
  colors?: string[];
  data: { name?: any; score?: any; n?: any; total?: any }[];
  categories?: any[];
};

const StackColumnChart = ({ colors, data, categories }: ColumnChartType) => {
  const options = {
    chart: {
      fontFamily: 'Prompt, Helvetica, Arial, sans-serif',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: data?.[0]?.score?.length > 3 ? '70%' : '30%',
        dataLabels: {
          position: 'center', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        if (data?.[0]?.total == 100) {
          return val?.toFixed?.(0) + ' %';
        }
        return val?.toFixed?.(0) + ' PTs';
      },
      offsetY: 3,
      style: {
        fontSize: data?.[0]?.score?.length > 5 ? '11px' : '13.5px',
        colors: ['#fff'],
      },
    },
    xaxis: {
      categories: categories || [],
    },
    yaxis: {
      max: data?.[0]?.total,
      labels: {
        formatter: function (val: any) {
          if (data?.[0]?.total == 100) {
            return val + ' %';
          }
          return val + ' PTs';
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
        },
      ],
    },
    legend: {
      show: false,
      // position: 'left' as const,
      // onItemClick: {
      //   toggleDataSeries: false,
      // },
      // fontSize: '13px',
      // markers: {
      //   radius: 20,
      // },
      // itemMargin: {
      //   vertical: 5,
      // },
    },
    colors: colors,
    states: {
      hover: {
        filter: {
          value: 0,
        },
      },
      active: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
  };
  const series = data.map?.((column) => ({ ...column, ...{ data: column?.score } }));

  return <Chart options={options} series={series} type="bar" height="100%" />;
};

export default StackColumnChart;
