import { useEffect, useState } from 'react';

import { CompareTableContainer } from './style';

interface CompareTableInterface {
  strongText: string;
  improveText: string;
  strongs: { no: string; name: string; score: string | number }[];
  improves: { no: string; name: string; score: string | number }[];
}

const CompareTable = ({ strongText, improveText, strongs, improves }: CompareTableInterface) => {
  const array = strongs?.length > improves?.length ? strongs : improves;

  return (
    <CompareTableContainer colors={['20, 184, 166', '239, 68, 68']}>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>{strongText || 'จุดแข็ง'}</th>
            <th>{improveText || 'จุดอ่อน'}</th>
          </tr>
        </thead>
        <tbody>
          {array?.map?.((rank, index) => {
            return (
              <tr key={index}>
                <td>Rank#{rank?.no}</td>
                <td>
                  <div className="content">
                    <div className="topic">{strongs?.[index]?.name}</div>
                    <div className="point">{strongs?.[index]?.score}</div>
                  </div>
                </td>
                <td>
                  <div className="content">
                    <div className="topic">{improves?.[index]?.name}</div>
                    <div className="point">{improves?.[index]?.score}</div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </CompareTableContainer>
  );
};

export default CompareTable;
